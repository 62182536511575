.landing-page {
    text-align: center;
    padding: 50px;
    background-color: #f0f0f0;
  }
  
  .landing-page h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }
  
  .landing-page p {
    font-size: 1.2em;
  }
  