.book-reviews {
    text-align: center;
    padding: 50px;
    background-color: #fff;
  }
  
  .book-reviews h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .book-reviews p {
    font-size: 1.1em;
  }
  